import { gql, useSubscription } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useMemo } from "react";
import { IoAlertOutline, IoClose } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import {
  ArticleExposureIcon,
  ArticleExposureState,
} from "@/containers/ExposureIcon";
import { PrintCoreExposure } from "@/containers/article/PrintCoreExposure";

import { ExposureIndicatorTooltip } from "./ExposureIndicatorTooltip";
import {
  ArticleExposure as ArticleExposureType,
  Exposure as ExposureType,
} from "./article/ArticleExposureSelect";
import { useResolvePublicationSlots } from "./article/util/publicationSlots";

type ExposureIndicatorProps = {
  articleExposure: ArticleExposureType;
  exposure: ExposureType;
};

const IndicatorContainer = (props: { children: React.ReactNode }) => {
  return (
    <div className="absolute -bottom-[15%] left-1/2">{props.children}</div>
  );
};

const TooltipContent = (props: any) => {
  useSubscription(ArticleExposurePlannedDatetimeSubscription, {
    variables: {
      where: {
        exposureId: { eq: props.exposure.id },
      },
    },
  });
  return <ExposureIndicatorTooltip {...props} />;
};

function articlePublicationIsWithinExposureSlots(values: {
  articleEndDate: string | null;
  exposureEndDate: string | null;
  exposureStartDate: string | null;
}) {
  return moment(values.articleEndDate).isBetween(
    moment(values.exposureStartDate),
    moment(values.exposureEndDate),
    undefined,
    "[]",
  );
}

function rangesAreNotEqual(
  articlePlanningRange: string | null,
  exposurePlanningRange: string | null,
) {
  return !articlePlanningRange && exposurePlanningRange;
}

export const useShowPosteriorWarning = (props: ExposureIndicatorProps) => {
  const { articleExposure, exposure } = props;
  const { article } = articleExposure;
  const articlePlanningRange = article.planning.range;
  const exposurePlanningRange = articleExposure.planning?.range;
  const { startDate: exposureStartDate, endDate: exposureEndDate } =
    useResolvePublicationSlots({
      plannedDate: articleExposure.planning?.date,
      range: exposurePlanningRange,
    });
  const { endDate: articleEndDate } = useResolvePublicationSlots({
    plannedDate: article.planning.date,
    range: articlePlanningRange,
  });
  if (!exposure.warnPosteriorArticlePublication || !exposureEndDate) {
    return false;
  }
  if (
    rangesAreNotEqual(articlePlanningRange, exposurePlanningRange) &&
    articlePublicationIsWithinExposureSlots({
      articleEndDate,
      exposureEndDate,
      exposureStartDate,
    })
  ) {
    return false;
  }
  return moment(exposureEndDate).isAfter(moment(articleEndDate));
};

export const useShowPriorAlert = (props: ExposureIndicatorProps) => {
  const { articleExposure, exposure } = props;
  const { article } = articleExposure;
  const articlePlanningRange = article.planning.range;
  const exposurePlanningRange = articleExposure.planning?.range;
  const { startDate: exposureStartDate, endDate: exposureEndDate } =
    useResolvePublicationSlots({
      plannedDate: articleExposure.planning?.date,
      range: exposurePlanningRange,
    });
  const { endDate: articleEndDate } = useResolvePublicationSlots({
    plannedDate: article.planning.date,
    range: articlePlanningRange,
  });
  if (
    !exposure.requiredPriorArticlePublication ||
    !exposureEndDate ||
    moment(articleEndDate).isSame(moment(exposureEndDate))
  ) {
    return false;
  }
  if (
    rangesAreNotEqual(articlePlanningRange, exposurePlanningRange) &&
    articlePublicationIsWithinExposureSlots({
      articleEndDate,
      exposureStartDate,
      exposureEndDate,
    })
  ) {
    return false;
  }
  return moment(exposureEndDate).isBefore(moment(article.planning.date));
};

const Exposure = (props: {
  articleExposure: ExposureIndicatorProps["articleExposure"];
  exposure: ExposureIndicatorProps["exposure"];
}) => {
  const { articleExposure, exposure } = props;
  const showPriorAlert = useShowPriorAlert({
    articleExposure,
    exposure,
  });
  const showPosteriorWarning = useShowPosteriorWarning({
    articleExposure,
    exposure,
  });
  const state = useMemo((): ArticleExposureState => {
    if (articleExposure.fulfilled) return "fulfilled";
    if (articleExposure.selected) return "selected";
    return "suggested";
  }, [exposure]);

  return (
    <Tooltip
      tooltip={
        <TooltipContent articleExposure={articleExposure} exposure={exposure} />
      }
    >
      <div className="relative flex" aria-label={exposure.label} role="status">
        <ArticleExposureIcon
          label={exposure.label}
          icon={exposure.icon}
          acronym={exposure.acronym}
          state={state}
        />
        <ExposurePlanningIndicators articleExposure={articleExposure}>
          {showPosteriorWarning ? (
            <IndicatorContainer>
              <PosteriorExposureWarning className="text-[0.8rem]" />
            </IndicatorContainer>
          ) : null}
          {showPriorAlert ? (
            <IndicatorContainer>
              <PriorExposureAlert className="text-[0.8rem]" />
            </IndicatorContainer>
          ) : null}
        </ExposurePlanningIndicators>
      </div>
    </Tooltip>
  );
};

export const PosteriorExposureWarning = (props: { className: string }) => {
  return (
    <div
      className={clsx(
        "rounded-full bg-warning-bg-strong ring-white",
        props.className,
      )}
    >
      <IoAlertOutline
        className="text-white"
        aria-label="Date d’exposition postérieure à la publication"
      />
    </div>
  );
};

export const PriorExposureAlert = (props: { className: string }) => {
  return (
    <div
      className={clsx(
        "rounded-full bg-danger-bg-strong ring-white",
        props.className,
      )}
    >
      <IoClose
        className="text-white"
        aria-label="Date d’exposition antérieure à la publication"
      />
    </div>
  );
};

function isNotConcernedByIndicators(props: {
  articleExposure: ArticleExposureType;
}) {
  const {
    articleExposure: {
      fulfilled,
      correlatedPlannedDateToArticle,
      article,
      planning,
    },
  } = props;
  return (
    fulfilled ||
    correlatedPlannedDateToArticle ||
    article.initialFirstPublished ||
    !planning?.date
  );
}

export const ExposurePlanningIndicators = (props: {
  articleExposure: ExposureIndicatorProps["articleExposure"];
  children: React.ReactNode;
}) => {
  if (isNotConcernedByIndicators({ articleExposure: props.articleExposure })) {
    return null;
  }
  return <>{props.children}</>;
};

export const ExposureIndicator = (props: {
  articleExposure: ExposureIndicatorProps["articleExposure"];
  exposure: ExposureIndicatorProps["exposure"];
  article: any;
}) => {
  const { articleExposure, exposure, article } = props;
  if (
    !articleExposure.suggested &&
    !articleExposure.fulfilled &&
    !articleExposure.selected
  )
    return null;
  if (exposure.type === "print") {
    return <PrintCoreExposure article={article} />;
  }
  return (
    <Exposure
      articleExposure={{ ...articleExposure, article }}
      exposure={exposure}
    />
  );
};

ExposureIndicator.fragments = {
  articleExposure: gql`
    fragment ExposureIndicator_articleExposure on ArticleExposure {
      gid
      exposureId
      suggested
      suggestedAt
      fulfilled
      selected
      selectedAt
      fulfilledAt
      publicationDate
      planning {
        range
        date
      }
    }
  `,
  exposure: gql`
    fragment ExposureIndicator_exposure on Exposure {
      id
      label
      ...ArticleExposureIcon_exposure
    }
    ${ArticleExposureIcon.fragments.exposure}
  `,
  publication: gql`
    fragment ExposureIndicator_publication on Publication {
      id
      date
    }
  `,
  article: gql`
    fragment ExposureIndicator_article on Article {
      id
      ...PrintCoreExposure_article
      planning {
        date
        range
      }
    }
    ${PrintCoreExposure.fragments.article}
  `,
};

const ArticleExposurePlannedDatetimeSubscription = gql`
  subscription ExposureIndicator_ArticleExposureUpdated(
    $where: ExposureArticleExposureWhere!
  ) {
    articleExposureUpdated(where: $where) {
      gid
      ...ExposureIndicator_articleExposure
    }
  }
  ${ExposureIndicator.fragments.articleExposure}
`;
